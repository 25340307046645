import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
const NotFoundPage = () => (
  <Layout>
    <Helmet titleTemplate="%s | 株式会社アーバ">
      <title>お探しのページが見つかりません</title>
      <meta
        name="description"
        content="404 error"
      />
    </Helmet>
    <div className="l-container">
      <h1 className="c-pageTitle" data-spacingsp="mb7">
        <span className="c-pageTitle__container">
          <span className="c-pageTitle__textJa u-font--termina">404</span>
          <span className="c-pageTitle__textEn">PAGE NOT FOUND</span>
        </span>
      </h1>
    </div>
    <div className="l-container--wide">
      <ul className="p-breadCrumbs">
        <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
        <li className="p-breadCrumbs__item isCurrent">404</li>
      </ul>
    </div>
    <main className="l-main" data-spacing="mb8 pb8">
      <div className="l-container">
        <div className="u-alignCenter u-lineHeight200 u-font--sp14">
          <p>
            申し訳ございません。
          </p>
          <p data-spacing="mb8">
            お探しのページは見つかりませんでした。
          </p>
          <p>
            <Link to="/" className="c-button--skewBorderYellow p-contact__submit" type="submit">
              <span className="c-icon--arrow c-button__arrow--back" />
              ホーム
            </Link>
          </p>
        </div>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
